/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { TypeLatLng } from './TypeLatLng';
import {
    TypeLatLngFromJSON,
    TypeLatLngFromJSONTyped,
    TypeLatLngToJSON,
} from './TypeLatLng';

/**
 * 
 * @export
 * @interface V1SearchResultExpertReservationLocalProfile
 */
export interface V1SearchResultExpertReservationLocalProfile {
    localProfileId: number;
    /**
     * 
     * @type {string}
     * @memberof V1SearchResultExpertReservationLocalProfile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchResultExpertReservationLocalProfile
     */
    categoryName: string;
    /**
     * 
     * @type {TypeLatLng}
     * @memberof V1SearchResultExpertReservationLocalProfile
     */
    latlng?: TypeLatLng;
    /**
     * 
     * @type {number}
     * @memberof V1SearchResultExpertReservationLocalProfile
     */
    reviewStarRatingAverage: number;
    /**
     * 
     * @type {number}
     * @memberof V1SearchResultExpertReservationLocalProfile
     */
    reviewCount: number;
    /**
     * 
     * @type {number}
     * @memberof V1SearchResultExpertReservationLocalProfile
     */
    follwerCount: number;
    /**
     * 
     * @type {string}
     * @memberof V1SearchResultExpertReservationLocalProfile
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchResultExpertReservationLocalProfile
     */
    profileImageUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1SearchResultExpertReservationLocalProfile
     */
    backgroundImageUrls: Array<string>;
}

/**
 * Check if a given object implements the V1SearchResultExpertReservationLocalProfile interface.
 */
export function instanceOfV1SearchResultExpertReservationLocalProfile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "categoryName" in value;
    isInstance = isInstance && "reviewStarRatingAverage" in value;
    isInstance = isInstance && "reviewCount" in value;
    isInstance = isInstance && "follwerCount" in value;
    isInstance = isInstance && "backgroundImageUrls" in value;

    return isInstance;
}

export function V1SearchResultExpertReservationLocalProfileFromJSON(json: any): V1SearchResultExpertReservationLocalProfile {
    return V1SearchResultExpertReservationLocalProfileFromJSONTyped(json, false);
}

export function V1SearchResultExpertReservationLocalProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SearchResultExpertReservationLocalProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'localProfileId': json['local_profile_id'],
        'name': json['name'],
        'categoryName': json['category_name'],
        'latlng': !exists(json, 'latlng') ? undefined : TypeLatLngFromJSON(json['latlng']),
        'reviewStarRatingAverage': json['review_star_rating_average'],
        'reviewCount': json['review_count'],
        'follwerCount': json['follwer_count'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'profileImageUrl': !exists(json, 'profile_image_url') ? undefined : json['profile_image_url'],
        'backgroundImageUrls': json['background_image_urls'],
    };
}

export function V1SearchResultExpertReservationLocalProfileToJSON(value?: V1SearchResultExpertReservationLocalProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'local_profile_id': value.localProfileId,
        'name': value.name,
        'category_name': value.categoryName,
        'latlng': TypeLatLngToJSON(value.latlng),
        'review_star_rating_average': value.reviewStarRatingAverage,
        'review_count': value.reviewCount,
        'follwer_count': value.follwerCount,
        'description': value.description,
        'profile_image_url': value.profileImageUrl,
        'background_image_urls': value.backgroundImageUrls,
    };
}

