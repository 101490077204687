/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../../../runtime';
import type {
  UpdateUserLocationRpcRequest,
  V1AddressAutoCompleteResponse,
  V1CreateUserLocationRequest,
  V1CreateUserLocationResponse,
  V1GetAddressByCoordinateResponse,
  V1GetExperimentSegmentsResponse,
  V1GetLocalProfileBusinessReviewPreviewResponse,
  V1GetLocalProfilePreviewResponse,
  V1GetRegionByLatLngResponse,
  V1GetSafeNumberByBusinessProfileIdResponse,
  V1GetUserProfileResponse,
  V1ListCommentaryMapPinItemsRequest,
  V1ListCommentaryMapPinItemsResponse,
  V1ListItemsBySectionViewTypesResponse,
  V1ListRecommendationChipsResponse,
  V1ListUserLocationsResponse,
  V1SearchAutoCompleteResponse,
  V1SearchLocalProfilesRequest,
  V1SearchLocalProfilesResponse,
  V1UpdateUserLocationResponse,
  V1VerifyEmployeeResponse,
  V2ErrorDetail,
} from '../models/index';
import {
    UpdateUserLocationRpcRequestFromJSON,
    UpdateUserLocationRpcRequestToJSON,
    V1AddressAutoCompleteResponseFromJSON,
    V1AddressAutoCompleteResponseToJSON,
    V1CreateUserLocationRequestFromJSON,
    V1CreateUserLocationRequestToJSON,
    V1CreateUserLocationResponseFromJSON,
    V1CreateUserLocationResponseToJSON,
    V1GetAddressByCoordinateResponseFromJSON,
    V1GetAddressByCoordinateResponseToJSON,
    V1GetExperimentSegmentsResponseFromJSON,
    V1GetExperimentSegmentsResponseToJSON,
    V1GetLocalProfileBusinessReviewPreviewResponseFromJSON,
    V1GetLocalProfileBusinessReviewPreviewResponseToJSON,
    V1GetLocalProfilePreviewResponseFromJSON,
    V1GetLocalProfilePreviewResponseToJSON,
    V1GetRegionByLatLngResponseFromJSON,
    V1GetRegionByLatLngResponseToJSON,
    V1GetSafeNumberByBusinessProfileIdResponseFromJSON,
    V1GetSafeNumberByBusinessProfileIdResponseToJSON,
    V1GetUserProfileResponseFromJSON,
    V1GetUserProfileResponseToJSON,
    V1ListCommentaryMapPinItemsRequestFromJSON,
    V1ListCommentaryMapPinItemsRequestToJSON,
    V1ListCommentaryMapPinItemsResponseFromJSON,
    V1ListCommentaryMapPinItemsResponseToJSON,
    V1ListItemsBySectionViewTypesResponseFromJSON,
    V1ListItemsBySectionViewTypesResponseToJSON,
    V1ListRecommendationChipsResponseFromJSON,
    V1ListRecommendationChipsResponseToJSON,
    V1ListUserLocationsResponseFromJSON,
    V1ListUserLocationsResponseToJSON,
    V1SearchAutoCompleteResponseFromJSON,
    V1SearchAutoCompleteResponseToJSON,
    V1SearchLocalProfilesRequestFromJSON,
    V1SearchLocalProfilesRequestToJSON,
    V1SearchLocalProfilesResponseFromJSON,
    V1SearchLocalProfilesResponseToJSON,
    V1UpdateUserLocationResponseFromJSON,
    V1UpdateUserLocationResponseToJSON,
    V1VerifyEmployeeResponseFromJSON,
    V1VerifyEmployeeResponseToJSON,
    V2ErrorDetailFromJSON,
    V2ErrorDetailToJSON,
} from '../models/index';

export interface LocalMapServiceAddressAutoCompleteRequest {
    prefix: string;
    pageSize: string;
    includeLandAddress: boolean;
    pageToken?: string;
    coordinatesLatitude?: number;
    coordinatesLongitude?: number;
    regionId?: string;
}

export interface LocalMapServiceClaimCommerceCouponRequest {
    commerceCouponId: string;
}

export interface LocalMapServiceCreateUserLocationRequest {
    v1CreateUserLocationRequest: V1CreateUserLocationRequest;
}

export interface LocalMapServiceDeleteBusinessReviewRequest {
    reviewId: string;
}

export interface LocalMapServiceDeleteUserLocationByIdRequest {
    encryptedId: string;
    body: object;
}

export interface LocalMapServiceFollowBusinessProfileRequest {
    businessProfileId: string;
}

export interface LocalMapServiceGetAddressByCoordinateRequest {
    coordinateLatitude?: number;
    coordinateLongitude?: number;
}

export interface LocalMapServiceGetLocalProfileBusinessReviewPreviewRequest {
    businessReviewId: string;
}

export interface LocalMapServiceGetLocalProfilePreviewRequest {
    localProfileId: string;
}

export interface LocalMapServiceGetRegionByLatLngRequest {
    latitude: number;
    longitude: number;
}

export interface LocalMapServiceGetSafeNumberByBusinessProfileIdRequest {
    businessProfileId: string;
}

export interface LocalMapServiceLikeBusinessReviewRequest {
    reviewId: string;
    body: object;
}

export interface LocalMapServiceListCommentaryMapPinItemsRequest {
    v1ListCommentaryMapPinItemsRequest: V1ListCommentaryMapPinItemsRequest;
}

export interface LocalMapServiceListItemsBySectionViewTypesRequest {
    sectionViewTypes: Array<LocalMapServiceListItemsBySectionViewTypesSectionViewTypesEnum>;
    userCoordinatesLatitude?: number;
    userCoordinatesLongitude?: number;
}

export interface LocalMapServiceListRecommendationChipsRequest {
    userCoordinatesLatitude?: number;
    userCoordinatesLongitude?: number;
}

export interface LocalMapServiceSearchAutoCompleteRequest {
    prefix: string;
    coordinatesForSearchLatitude?: number;
    coordinatesForSearchLongitude?: number;
    coordinatesForDistanceLatitude?: number;
    coordinatesForDistanceLongitude?: number;
}

export interface LocalMapServiceSearchLocalProfilesRequest {
    v1SearchLocalProfilesRequest: V1SearchLocalProfilesRequest;
}

export interface LocalMapServiceSearchLocalProfilesTemporaryRequest {
    query: string;
    pageSize: number;
    searchEventClientDataQueryId: string;
    searchEventClientDataFunnelFrom: string;
    searchEventClientDataQueryFrom: string;
    searchEventClientDataScreenDepthName: string;
    searchEventClientDataSearchFunnelId: string;
    nextPageToken?: string;
    coordinatesForSearchLatitude?: number;
    coordinatesForSearchLongitude?: number;
    coordinatesForDistanceLatitude?: number;
    coordinatesForDistanceLongitude?: number;
    boundsForSearchCenterLatitude?: number;
    boundsForSearchCenterLongitude?: number;
    boundsForSearchNorthWestLatitude?: number;
    boundsForSearchNorthWestLongitude?: number;
    boundsForSearchSouthEastLatitude?: number;
    boundsForSearchSouthEastLongitude?: number;
    userViewBoundsCenterLatitude?: number;
    userViewBoundsCenterLongitude?: number;
    userViewBoundsNorthWestLatitude?: number;
    userViewBoundsNorthWestLongitude?: number;
    userViewBoundsSouthEastLatitude?: number;
    userViewBoundsSouthEastLongitude?: number;
}

export interface LocalMapServiceUnlikeBusinessReviewRequest {
    reviewId: string;
}

export interface LocalMapServiceUpdateUserLocationRequest {
    encryptedId: string;
    updateUserLocationRpcRequest: UpdateUserLocationRpcRequest;
}

/**
 * 
 */
export class LocalMapServiceApi extends runtime.BaseAPI {

    /**
     * 집주소 검색 자동완성
     */
    async localMapServiceAddressAutoCompleteRaw(requestParameters: LocalMapServiceAddressAutoCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1AddressAutoCompleteResponse>> {
        if (requestParameters.prefix === null || requestParameters.prefix === undefined) {
            throw new runtime.RequiredError('prefix','Required parameter requestParameters.prefix was null or undefined when calling localMapServiceAddressAutoComplete.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling localMapServiceAddressAutoComplete.');
        }

        if (requestParameters.includeLandAddress === null || requestParameters.includeLandAddress === undefined) {
            throw new runtime.RequiredError('includeLandAddress','Required parameter requestParameters.includeLandAddress was null or undefined when calling localMapServiceAddressAutoComplete.');
        }

        const queryParameters: any = {};

        if (requestParameters.prefix !== undefined) {
            queryParameters['prefix'] = requestParameters.prefix;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        if (requestParameters.coordinatesLatitude !== undefined) {
            queryParameters['coordinates.latitude'] = requestParameters.coordinatesLatitude;
        }

        if (requestParameters.coordinatesLongitude !== undefined) {
            queryParameters['coordinates.longitude'] = requestParameters.coordinatesLongitude;
        }

        if (requestParameters.regionId !== undefined) {
            queryParameters['region_id'] = requestParameters.regionId;
        }

        if (requestParameters.includeLandAddress !== undefined) {
            queryParameters['include_land_address'] = requestParameters.includeLandAddress;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/address-auto-complete`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1AddressAutoCompleteResponseFromJSON(jsonValue));
    }

    /**
     * 집주소 검색 자동완성
     */
    async localMapServiceAddressAutoComplete(requestParameters: LocalMapServiceAddressAutoCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1AddressAutoCompleteResponse> {
        const response = await this.localMapServiceAddressAutoCompleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 쿠폰을 받아요
     */
    async localMapServiceClaimCommerceCouponRaw(requestParameters: LocalMapServiceClaimCommerceCouponRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.commerceCouponId === null || requestParameters.commerceCouponId === undefined) {
            throw new runtime.RequiredError('commerceCouponId','Required parameter requestParameters.commerceCouponId was null or undefined when calling localMapServiceClaimCommerceCoupon.');
        }

        const queryParameters: any = {};

        if (requestParameters.commerceCouponId !== undefined) {
            queryParameters['commerce_coupon_id'] = requestParameters.commerceCouponId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/commerce-coupon/claim`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * 쿠폰을 받아요
     */
    async localMapServiceClaimCommerceCoupon(requestParameters: LocalMapServiceClaimCommerceCouponRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.localMapServiceClaimCommerceCouponRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 사용자 위치 정보를 생성해요
     */
    async localMapServiceCreateUserLocationRaw(requestParameters: LocalMapServiceCreateUserLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1CreateUserLocationResponse>> {
        if (requestParameters.v1CreateUserLocationRequest === null || requestParameters.v1CreateUserLocationRequest === undefined) {
            throw new runtime.RequiredError('v1CreateUserLocationRequest','Required parameter requestParameters.v1CreateUserLocationRequest was null or undefined when calling localMapServiceCreateUserLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user-location`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: V1CreateUserLocationRequestToJSON(requestParameters.v1CreateUserLocationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1CreateUserLocationResponseFromJSON(jsonValue));
    }

    /**
     * 사용자 위치 정보를 생성해요
     */
    async localMapServiceCreateUserLocation(requestParameters: LocalMapServiceCreateUserLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1CreateUserLocationResponse> {
        const response = await this.localMapServiceCreateUserLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 후기 삭제하기 API
     */
    async localMapServiceDeleteBusinessReviewRaw(requestParameters: LocalMapServiceDeleteBusinessReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.reviewId === null || requestParameters.reviewId === undefined) {
            throw new runtime.RequiredError('reviewId','Required parameter requestParameters.reviewId was null or undefined when calling localMapServiceDeleteBusinessReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/business-reviews/{review_id}`.replace(`{${"review_id"}}`, encodeURIComponent(String(requestParameters.reviewId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * 후기 삭제하기 API
     */
    async localMapServiceDeleteBusinessReview(requestParameters: LocalMapServiceDeleteBusinessReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.localMapServiceDeleteBusinessReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 사용자 위치 정보를 삭제해요
     */
    async localMapServiceDeleteUserLocationByIdRaw(requestParameters: LocalMapServiceDeleteUserLocationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.encryptedId === null || requestParameters.encryptedId === undefined) {
            throw new runtime.RequiredError('encryptedId','Required parameter requestParameters.encryptedId was null or undefined when calling localMapServiceDeleteUserLocationById.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling localMapServiceDeleteUserLocationById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user-location/{encrypted_id}`.replace(`{${"encrypted_id"}}`, encodeURIComponent(String(requestParameters.encryptedId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * 사용자 위치 정보를 삭제해요
     */
    async localMapServiceDeleteUserLocationById(requestParameters: LocalMapServiceDeleteUserLocationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.localMapServiceDeleteUserLocationByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 비즈프로필 단골을 추가해요
     */
    async localMapServiceFollowBusinessProfileRaw(requestParameters: LocalMapServiceFollowBusinessProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.businessProfileId === null || requestParameters.businessProfileId === undefined) {
            throw new runtime.RequiredError('businessProfileId','Required parameter requestParameters.businessProfileId was null or undefined when calling localMapServiceFollowBusinessProfile.');
        }

        const queryParameters: any = {};

        if (requestParameters.businessProfileId !== undefined) {
            queryParameters['business_profile_id'] = requestParameters.businessProfileId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/business-profile/follow`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * 비즈프로필 단골을 추가해요
     */
    async localMapServiceFollowBusinessProfile(requestParameters: LocalMapServiceFollowBusinessProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.localMapServiceFollowBusinessProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 좌표로 주소를 가져와요
     */
    async localMapServiceGetAddressByCoordinateRaw(requestParameters: LocalMapServiceGetAddressByCoordinateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1GetAddressByCoordinateResponse>> {
        const queryParameters: any = {};

        if (requestParameters.coordinateLatitude !== undefined) {
            queryParameters['coordinate.latitude'] = requestParameters.coordinateLatitude;
        }

        if (requestParameters.coordinateLongitude !== undefined) {
            queryParameters['coordinate.longitude'] = requestParameters.coordinateLongitude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/geocode/address`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1GetAddressByCoordinateResponseFromJSON(jsonValue));
    }

    /**
     * 좌표로 주소를 가져와요
     */
    async localMapServiceGetAddressByCoordinate(requestParameters: LocalMapServiceGetAddressByCoordinateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1GetAddressByCoordinateResponse> {
        const response = await this.localMapServiceGetAddressByCoordinateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 진행중인 실험 정보를 내려줘요
     */
    async localMapServiceGetExperimentSegmentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1GetExperimentSegmentsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/experiment-segments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1GetExperimentSegmentsResponseFromJSON(jsonValue));
    }

    /**
     * 진행중인 실험 정보를 내려줘요
     */
    async localMapServiceGetExperimentSegments(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1GetExperimentSegmentsResponse> {
        const response = await this.localMapServiceGetExperimentSegmentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * LP 후기 프리뷰 조회
     */
    async localMapServiceGetLocalProfileBusinessReviewPreviewRaw(requestParameters: LocalMapServiceGetLocalProfileBusinessReviewPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1GetLocalProfileBusinessReviewPreviewResponse>> {
        if (requestParameters.businessReviewId === null || requestParameters.businessReviewId === undefined) {
            throw new runtime.RequiredError('businessReviewId','Required parameter requestParameters.businessReviewId was null or undefined when calling localMapServiceGetLocalProfileBusinessReviewPreview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/local-profile-business-reviews/{business_review_id}/preview`.replace(`{${"business_review_id"}}`, encodeURIComponent(String(requestParameters.businessReviewId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1GetLocalProfileBusinessReviewPreviewResponseFromJSON(jsonValue));
    }

    /**
     * LP 후기 프리뷰 조회
     */
    async localMapServiceGetLocalProfileBusinessReviewPreview(requestParameters: LocalMapServiceGetLocalProfileBusinessReviewPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1GetLocalProfileBusinessReviewPreviewResponse> {
        const response = await this.localMapServiceGetLocalProfileBusinessReviewPreviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 로컬 프로필 프리뷰
     */
    async localMapServiceGetLocalProfilePreviewRaw(requestParameters: LocalMapServiceGetLocalProfilePreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1GetLocalProfilePreviewResponse>> {
        if (requestParameters.localProfileId === null || requestParameters.localProfileId === undefined) {
            throw new runtime.RequiredError('localProfileId','Required parameter requestParameters.localProfileId was null or undefined when calling localMapServiceGetLocalProfilePreview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/local-profile/{local_profile_id}/preview`.replace(`{${"local_profile_id"}}`, encodeURIComponent(String(requestParameters.localProfileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1GetLocalProfilePreviewResponseFromJSON(jsonValue));
    }

    /**
     * 로컬 프로필 프리뷰
     */
    async localMapServiceGetLocalProfilePreview(requestParameters: LocalMapServiceGetLocalProfilePreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1GetLocalProfilePreviewResponse> {
        const response = await this.localMapServiceGetLocalProfilePreviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 좌표로 리전을 조회해요
     */
    async localMapServiceGetRegionByLatLngRaw(requestParameters: LocalMapServiceGetRegionByLatLngRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1GetRegionByLatLngResponse>> {
        if (requestParameters.latitude === null || requestParameters.latitude === undefined) {
            throw new runtime.RequiredError('latitude','Required parameter requestParameters.latitude was null or undefined when calling localMapServiceGetRegionByLatLng.');
        }

        if (requestParameters.longitude === null || requestParameters.longitude === undefined) {
            throw new runtime.RequiredError('longitude','Required parameter requestParameters.longitude was null or undefined when calling localMapServiceGetRegionByLatLng.');
        }

        const queryParameters: any = {};

        if (requestParameters.latitude !== undefined) {
            queryParameters['latitude'] = requestParameters.latitude;
        }

        if (requestParameters.longitude !== undefined) {
            queryParameters['longitude'] = requestParameters.longitude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/region-by-latlng`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1GetRegionByLatLngResponseFromJSON(jsonValue));
    }

    /**
     * 좌표로 리전을 조회해요
     */
    async localMapServiceGetRegionByLatLng(requestParameters: LocalMapServiceGetRegionByLatLngRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1GetRegionByLatLngResponse> {
        const response = await this.localMapServiceGetRegionByLatLngRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 비즈프로필 아이디로 안심번호를 가져와요
     */
    async localMapServiceGetSafeNumberByBusinessProfileIdRaw(requestParameters: LocalMapServiceGetSafeNumberByBusinessProfileIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1GetSafeNumberByBusinessProfileIdResponse>> {
        if (requestParameters.businessProfileId === null || requestParameters.businessProfileId === undefined) {
            throw new runtime.RequiredError('businessProfileId','Required parameter requestParameters.businessProfileId was null or undefined when calling localMapServiceGetSafeNumberByBusinessProfileId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/business-profile/{business_profile_id}/safe-number`.replace(`{${"business_profile_id"}}`, encodeURIComponent(String(requestParameters.businessProfileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1GetSafeNumberByBusinessProfileIdResponseFromJSON(jsonValue));
    }

    /**
     * 비즈프로필 아이디로 안심번호를 가져와요
     */
    async localMapServiceGetSafeNumberByBusinessProfileId(requestParameters: LocalMapServiceGetSafeNumberByBusinessProfileIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1GetSafeNumberByBusinessProfileIdResponse> {
        const response = await this.localMapServiceGetSafeNumberByBusinessProfileIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 사용자 프로필 조회
     */
    async localMapServiceGetUserProfileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1GetUserProfileResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/get-user-profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1GetUserProfileResponseFromJSON(jsonValue));
    }

    /**
     * 사용자 프로필 조회
     */
    async localMapServiceGetUserProfile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1GetUserProfileResponse> {
        const response = await this.localMapServiceGetUserProfileRaw(initOverrides);
        return await response.value();
    }

    /**
     * 후기 도움돼요
     */
    async localMapServiceLikeBusinessReviewRaw(requestParameters: LocalMapServiceLikeBusinessReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.reviewId === null || requestParameters.reviewId === undefined) {
            throw new runtime.RequiredError('reviewId','Required parameter requestParameters.reviewId was null or undefined when calling localMapServiceLikeBusinessReview.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling localMapServiceLikeBusinessReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/business-reviews/{review_id}/like`.replace(`{${"review_id"}}`, encodeURIComponent(String(requestParameters.reviewId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * 후기 도움돼요
     */
    async localMapServiceLikeBusinessReview(requestParameters: LocalMapServiceLikeBusinessReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.localMapServiceLikeBusinessReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async localMapServiceListCommentaryMapPinItemsRaw(requestParameters: LocalMapServiceListCommentaryMapPinItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1ListCommentaryMapPinItemsResponse>> {
        if (requestParameters.v1ListCommentaryMapPinItemsRequest === null || requestParameters.v1ListCommentaryMapPinItemsRequest === undefined) {
            throw new runtime.RequiredError('v1ListCommentaryMapPinItemsRequest','Required parameter requestParameters.v1ListCommentaryMapPinItemsRequest was null or undefined when calling localMapServiceListCommentaryMapPinItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/commentary-map-pin-items`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: V1ListCommentaryMapPinItemsRequestToJSON(requestParameters.v1ListCommentaryMapPinItemsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ListCommentaryMapPinItemsResponseFromJSON(jsonValue));
    }

    /**
     */
    async localMapServiceListCommentaryMapPinItems(requestParameters: LocalMapServiceListCommentaryMapPinItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1ListCommentaryMapPinItemsResponse> {
        const response = await this.localMapServiceListCommentaryMapPinItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 피드 아이템 조회
     */
    async localMapServiceListItemsBySectionViewTypesRaw(requestParameters: LocalMapServiceListItemsBySectionViewTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1ListItemsBySectionViewTypesResponse>> {
        if (requestParameters.sectionViewTypes === null || requestParameters.sectionViewTypes === undefined) {
            throw new runtime.RequiredError('sectionViewTypes','Required parameter requestParameters.sectionViewTypes was null or undefined when calling localMapServiceListItemsBySectionViewTypes.');
        }

        const queryParameters: any = {};

        if (requestParameters.sectionViewTypes) {
            queryParameters['section_view_types'] = requestParameters.sectionViewTypes;
        }

        if (requestParameters.userCoordinatesLatitude !== undefined) {
            queryParameters['user_coordinates.latitude'] = requestParameters.userCoordinatesLatitude;
        }

        if (requestParameters.userCoordinatesLongitude !== undefined) {
            queryParameters['user_coordinates.longitude'] = requestParameters.userCoordinatesLongitude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/list-items-by-section-view-types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ListItemsBySectionViewTypesResponseFromJSON(jsonValue));
    }

    /**
     * 피드 아이템 조회
     */
    async localMapServiceListItemsBySectionViewTypes(requestParameters: LocalMapServiceListItemsBySectionViewTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1ListItemsBySectionViewTypesResponse> {
        const response = await this.localMapServiceListItemsBySectionViewTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 추천 칩을 모두 가져와요
     */
    async localMapServiceListRecommendationChipsRaw(requestParameters: LocalMapServiceListRecommendationChipsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1ListRecommendationChipsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.userCoordinatesLatitude !== undefined) {
            queryParameters['user_coordinates.latitude'] = requestParameters.userCoordinatesLatitude;
        }

        if (requestParameters.userCoordinatesLongitude !== undefined) {
            queryParameters['user_coordinates.longitude'] = requestParameters.userCoordinatesLongitude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/recommendation-chips`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ListRecommendationChipsResponseFromJSON(jsonValue));
    }

    /**
     * 추천 칩을 모두 가져와요
     */
    async localMapServiceListRecommendationChips(requestParameters: LocalMapServiceListRecommendationChipsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1ListRecommendationChipsResponse> {
        const response = await this.localMapServiceListRecommendationChipsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 요청하는 유저의 위치 정보를 모두 가져와요
     */
    async localMapServiceListUserLocationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1ListUserLocationsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/me/user-locations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ListUserLocationsResponseFromJSON(jsonValue));
    }

    /**
     * 요청하는 유저의 위치 정보를 모두 가져와요
     */
    async localMapServiceListUserLocations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1ListUserLocationsResponse> {
        const response = await this.localMapServiceListUserLocationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 메인 검색 자동완성
     */
    async localMapServiceSearchAutoCompleteRaw(requestParameters: LocalMapServiceSearchAutoCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1SearchAutoCompleteResponse>> {
        if (requestParameters.prefix === null || requestParameters.prefix === undefined) {
            throw new runtime.RequiredError('prefix','Required parameter requestParameters.prefix was null or undefined when calling localMapServiceSearchAutoComplete.');
        }

        const queryParameters: any = {};

        if (requestParameters.prefix !== undefined) {
            queryParameters['prefix'] = requestParameters.prefix;
        }

        if (requestParameters.coordinatesForSearchLatitude !== undefined) {
            queryParameters['coordinates_for_search.latitude'] = requestParameters.coordinatesForSearchLatitude;
        }

        if (requestParameters.coordinatesForSearchLongitude !== undefined) {
            queryParameters['coordinates_for_search.longitude'] = requestParameters.coordinatesForSearchLongitude;
        }

        if (requestParameters.coordinatesForDistanceLatitude !== undefined) {
            queryParameters['coordinates_for_distance.latitude'] = requestParameters.coordinatesForDistanceLatitude;
        }

        if (requestParameters.coordinatesForDistanceLongitude !== undefined) {
            queryParameters['coordinates_for_distance.longitude'] = requestParameters.coordinatesForDistanceLongitude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/search-auto-complete`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1SearchAutoCompleteResponseFromJSON(jsonValue));
    }

    /**
     * 메인 검색 자동완성
     */
    async localMapServiceSearchAutoComplete(requestParameters: LocalMapServiceSearchAutoCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1SearchAutoCompleteResponse> {
        const response = await this.localMapServiceSearchAutoCompleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * buf:lint:ignore RPC_RESPONSE_STANDARD_NAME buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE
     */
    async localMapServiceSearchLocalProfilesRaw(requestParameters: LocalMapServiceSearchLocalProfilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1SearchLocalProfilesResponse>> {
        if (requestParameters.v1SearchLocalProfilesRequest === null || requestParameters.v1SearchLocalProfilesRequest === undefined) {
            throw new runtime.RequiredError('v1SearchLocalProfilesRequest','Required parameter requestParameters.v1SearchLocalProfilesRequest was null or undefined when calling localMapServiceSearchLocalProfiles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/search-local-profiles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: V1SearchLocalProfilesRequestToJSON(requestParameters.v1SearchLocalProfilesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1SearchLocalProfilesResponseFromJSON(jsonValue));
    }

    /**
     * buf:lint:ignore RPC_RESPONSE_STANDARD_NAME buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE
     */
    async localMapServiceSearchLocalProfiles(requestParameters: LocalMapServiceSearchLocalProfilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1SearchLocalProfilesResponse> {
        const response = await this.localMapServiceSearchLocalProfilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO(neth): http end-point 변경 완료 후 제거할 API 이므로 lint 예외처리. 추후 삭제 예정. buf:lint:ignore RPC_RESPONSE_STANDARD_NAME buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE buf:lint:ignore RPC_REQUEST_STANDARD_NAME
     */
    async localMapServiceSearchLocalProfilesTemporaryRaw(requestParameters: LocalMapServiceSearchLocalProfilesTemporaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1SearchLocalProfilesResponse>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling localMapServiceSearchLocalProfilesTemporary.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling localMapServiceSearchLocalProfilesTemporary.');
        }

        if (requestParameters.searchEventClientDataQueryId === null || requestParameters.searchEventClientDataQueryId === undefined) {
            throw new runtime.RequiredError('searchEventClientDataQueryId','Required parameter requestParameters.searchEventClientDataQueryId was null or undefined when calling localMapServiceSearchLocalProfilesTemporary.');
        }

        if (requestParameters.searchEventClientDataFunnelFrom === null || requestParameters.searchEventClientDataFunnelFrom === undefined) {
            throw new runtime.RequiredError('searchEventClientDataFunnelFrom','Required parameter requestParameters.searchEventClientDataFunnelFrom was null or undefined when calling localMapServiceSearchLocalProfilesTemporary.');
        }

        if (requestParameters.searchEventClientDataQueryFrom === null || requestParameters.searchEventClientDataQueryFrom === undefined) {
            throw new runtime.RequiredError('searchEventClientDataQueryFrom','Required parameter requestParameters.searchEventClientDataQueryFrom was null or undefined when calling localMapServiceSearchLocalProfilesTemporary.');
        }

        if (requestParameters.searchEventClientDataScreenDepthName === null || requestParameters.searchEventClientDataScreenDepthName === undefined) {
            throw new runtime.RequiredError('searchEventClientDataScreenDepthName','Required parameter requestParameters.searchEventClientDataScreenDepthName was null or undefined when calling localMapServiceSearchLocalProfilesTemporary.');
        }

        if (requestParameters.searchEventClientDataSearchFunnelId === null || requestParameters.searchEventClientDataSearchFunnelId === undefined) {
            throw new runtime.RequiredError('searchEventClientDataSearchFunnelId','Required parameter requestParameters.searchEventClientDataSearchFunnelId was null or undefined when calling localMapServiceSearchLocalProfilesTemporary.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['next_page_token'] = requestParameters.nextPageToken;
        }

        if (requestParameters.coordinatesForSearchLatitude !== undefined) {
            queryParameters['coordinates_for_search.latitude'] = requestParameters.coordinatesForSearchLatitude;
        }

        if (requestParameters.coordinatesForSearchLongitude !== undefined) {
            queryParameters['coordinates_for_search.longitude'] = requestParameters.coordinatesForSearchLongitude;
        }

        if (requestParameters.coordinatesForDistanceLatitude !== undefined) {
            queryParameters['coordinates_for_distance.latitude'] = requestParameters.coordinatesForDistanceLatitude;
        }

        if (requestParameters.coordinatesForDistanceLongitude !== undefined) {
            queryParameters['coordinates_for_distance.longitude'] = requestParameters.coordinatesForDistanceLongitude;
        }

        if (requestParameters.boundsForSearchCenterLatitude !== undefined) {
            queryParameters['bounds_for_search.center.latitude'] = requestParameters.boundsForSearchCenterLatitude;
        }

        if (requestParameters.boundsForSearchCenterLongitude !== undefined) {
            queryParameters['bounds_for_search.center.longitude'] = requestParameters.boundsForSearchCenterLongitude;
        }

        if (requestParameters.boundsForSearchNorthWestLatitude !== undefined) {
            queryParameters['bounds_for_search.north_west.latitude'] = requestParameters.boundsForSearchNorthWestLatitude;
        }

        if (requestParameters.boundsForSearchNorthWestLongitude !== undefined) {
            queryParameters['bounds_for_search.north_west.longitude'] = requestParameters.boundsForSearchNorthWestLongitude;
        }

        if (requestParameters.boundsForSearchSouthEastLatitude !== undefined) {
            queryParameters['bounds_for_search.south_east.latitude'] = requestParameters.boundsForSearchSouthEastLatitude;
        }

        if (requestParameters.boundsForSearchSouthEastLongitude !== undefined) {
            queryParameters['bounds_for_search.south_east.longitude'] = requestParameters.boundsForSearchSouthEastLongitude;
        }

        if (requestParameters.searchEventClientDataQueryId !== undefined) {
            queryParameters['search_event_client_data.query_id'] = requestParameters.searchEventClientDataQueryId;
        }

        if (requestParameters.searchEventClientDataFunnelFrom !== undefined) {
            queryParameters['search_event_client_data.funnel_from'] = requestParameters.searchEventClientDataFunnelFrom;
        }

        if (requestParameters.searchEventClientDataQueryFrom !== undefined) {
            queryParameters['search_event_client_data.query_from'] = requestParameters.searchEventClientDataQueryFrom;
        }

        if (requestParameters.searchEventClientDataScreenDepthName !== undefined) {
            queryParameters['search_event_client_data.screen_depth_name'] = requestParameters.searchEventClientDataScreenDepthName;
        }

        if (requestParameters.searchEventClientDataSearchFunnelId !== undefined) {
            queryParameters['search_event_client_data.search_funnel_id'] = requestParameters.searchEventClientDataSearchFunnelId;
        }

        if (requestParameters.userViewBoundsCenterLatitude !== undefined) {
            queryParameters['user_view_bounds.center.latitude'] = requestParameters.userViewBoundsCenterLatitude;
        }

        if (requestParameters.userViewBoundsCenterLongitude !== undefined) {
            queryParameters['user_view_bounds.center.longitude'] = requestParameters.userViewBoundsCenterLongitude;
        }

        if (requestParameters.userViewBoundsNorthWestLatitude !== undefined) {
            queryParameters['user_view_bounds.north_west.latitude'] = requestParameters.userViewBoundsNorthWestLatitude;
        }

        if (requestParameters.userViewBoundsNorthWestLongitude !== undefined) {
            queryParameters['user_view_bounds.north_west.longitude'] = requestParameters.userViewBoundsNorthWestLongitude;
        }

        if (requestParameters.userViewBoundsSouthEastLatitude !== undefined) {
            queryParameters['user_view_bounds.south_east.latitude'] = requestParameters.userViewBoundsSouthEastLatitude;
        }

        if (requestParameters.userViewBoundsSouthEastLongitude !== undefined) {
            queryParameters['user_view_bounds.south_east.longitude'] = requestParameters.userViewBoundsSouthEastLongitude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/search/local-profiles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1SearchLocalProfilesResponseFromJSON(jsonValue));
    }

    /**
     * TODO(neth): http end-point 변경 완료 후 제거할 API 이므로 lint 예외처리. 추후 삭제 예정. buf:lint:ignore RPC_RESPONSE_STANDARD_NAME buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE buf:lint:ignore RPC_REQUEST_STANDARD_NAME
     */
    async localMapServiceSearchLocalProfilesTemporary(requestParameters: LocalMapServiceSearchLocalProfilesTemporaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1SearchLocalProfilesResponse> {
        const response = await this.localMapServiceSearchLocalProfilesTemporaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 후기 도움돼요 취소
     */
    async localMapServiceUnlikeBusinessReviewRaw(requestParameters: LocalMapServiceUnlikeBusinessReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.reviewId === null || requestParameters.reviewId === undefined) {
            throw new runtime.RequiredError('reviewId','Required parameter requestParameters.reviewId was null or undefined when calling localMapServiceUnlikeBusinessReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/business-reviews/{review_id}/like`.replace(`{${"review_id"}}`, encodeURIComponent(String(requestParameters.reviewId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * 후기 도움돼요 취소
     */
    async localMapServiceUnlikeBusinessReview(requestParameters: LocalMapServiceUnlikeBusinessReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.localMapServiceUnlikeBusinessReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 사용자 위치 정보를 수정해요
     */
    async localMapServiceUpdateUserLocationRaw(requestParameters: LocalMapServiceUpdateUserLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1UpdateUserLocationResponse>> {
        if (requestParameters.encryptedId === null || requestParameters.encryptedId === undefined) {
            throw new runtime.RequiredError('encryptedId','Required parameter requestParameters.encryptedId was null or undefined when calling localMapServiceUpdateUserLocation.');
        }

        if (requestParameters.updateUserLocationRpcRequest === null || requestParameters.updateUserLocationRpcRequest === undefined) {
            throw new runtime.RequiredError('updateUserLocationRpcRequest','Required parameter requestParameters.updateUserLocationRpcRequest was null or undefined when calling localMapServiceUpdateUserLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user-location/{encrypted_id}`.replace(`{${"encrypted_id"}}`, encodeURIComponent(String(requestParameters.encryptedId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserLocationRpcRequestToJSON(requestParameters.updateUserLocationRpcRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1UpdateUserLocationResponseFromJSON(jsonValue));
    }

    /**
     * 사용자 위치 정보를 수정해요
     */
    async localMapServiceUpdateUserLocation(requestParameters: LocalMapServiceUpdateUserLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1UpdateUserLocationResponse> {
        const response = await this.localMapServiceUpdateUserLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 사내 구성원 여부 조회
     */
    async localMapServiceVerifyEmployeeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1VerifyEmployeeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/verify/employee`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1VerifyEmployeeResponseFromJSON(jsonValue));
    }

    /**
     * 사내 구성원 여부 조회
     */
    async localMapServiceVerifyEmployee(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1VerifyEmployeeResponse> {
        const response = await this.localMapServiceVerifyEmployeeRaw(initOverrides);
        return await response.value();
    }
}

/**
 * @export
 */
export const LocalMapServiceListItemsBySectionViewTypesSectionViewTypesEnum = {
    Unspecified: 'SECTION_VIEW_TYPE_UNSPECIFIED',
    LocalStorySection: 'SECTION_VIEW_TYPE_LOCAL_STORY_SECTION',
    RecommendationBusinessPostSection: 'SECTION_VIEW_TYPE_RECOMMENDATION_BUSINESS_POST_SECTION',
    PublicInstitutionBusinessPostSection: 'SECTION_VIEW_TYPE_PUBLIC_INSTITUTION_BUSINESS_POST_SECTION',
    PopularCommerceCouponSection: 'SECTION_VIEW_TYPE_POPULAR_COMMERCE_COUPON_SECTION',
    ServiceOpenSurveySection: 'SECTION_VIEW_TYPE_SERVICE_OPEN_SURVEY_SECTION',
    CreateBusinessProfileAndSuggestPoiButtonSection: 'SECTION_VIEW_TYPE_CREATE_BUSINESS_PROFILE_AND_SUGGEST_POI_BUTTON_SECTION',
    FollowBusinessPostSection: 'SECTION_VIEW_TYPE_FOLLOW_BUSINESS_POST_SECTION',
    SocialCapitalTargetRegionSection: 'SECTION_VIEW_TYPE_SOCIAL_CAPITAL_TARGET_REGION_SECTION',
    SocialCapitalNearbyRegionSection: 'SECTION_VIEW_TYPE_SOCIAL_CAPITAL_NEARBY_REGION_SECTION',
    BusinessThemePostSection: 'SECTION_VIEW_TYPE_BUSINESS_THEME_POST_SECTION',
    NativeRecommendationLocalProfilesRankingSection: 'SECTION_VIEW_TYPE_NATIVE_RECOMMENDATION_LOCAL_PROFILES_RANKING_SECTION',
    ExpertQuestSection: 'SECTION_VIEW_TYPE_EXPERT_QUEST_SECTION'
} as const;
export type LocalMapServiceListItemsBySectionViewTypesSectionViewTypesEnum = typeof LocalMapServiceListItemsBySectionViewTypesSectionViewTypesEnum[keyof typeof LocalMapServiceListItemsBySectionViewTypesSectionViewTypesEnum];
